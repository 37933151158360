// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apps-js": () => import("./../../../src/pages/apps.js" /* webpackChunkName: "component---src-pages-apps-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-chamur-js": () => import("./../../../src/pages/chamur.js" /* webpackChunkName: "component---src-pages-chamur-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-games-js": () => import("./../../../src/pages/games.js" /* webpackChunkName: "component---src-pages-games-js" */),
  "component---src-pages-help-js": () => import("./../../../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-huni-js": () => import("./../../../src/pages/huni.js" /* webpackChunkName: "component---src-pages-huni-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leeloo-js": () => import("./../../../src/pages/leeloo.js" /* webpackChunkName: "component---src-pages-leeloo-js" */),
  "component---src-pages-licensing-js": () => import("./../../../src/pages/licensing.js" /* webpackChunkName: "component---src-pages-licensing-js" */),
  "component---src-pages-samsung-js": () => import("./../../../src/pages/samsung.js" /* webpackChunkName: "component---src-pages-samsung-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-wingo-js": () => import("./../../../src/pages/wingo.js" /* webpackChunkName: "component---src-pages-wingo-js" */),
  "component---src-templates-activities-js": () => import("./../../../src/templates/activities.js" /* webpackChunkName: "component---src-templates-activities-js" */),
  "component---src-templates-activity-js": () => import("./../../../src/templates/activity.js" /* webpackChunkName: "component---src-templates-activity-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-card-js": () => import("./../../../src/templates/card.js" /* webpackChunkName: "component---src-templates-card-js" */),
  "component---src-templates-games-js": () => import("./../../../src/templates/games.js" /* webpackChunkName: "component---src-templates-games-js" */),
  "component---src-templates-pack-js": () => import("./../../../src/templates/pack.js" /* webpackChunkName: "component---src-templates-pack-js" */),
  "component---src-templates-packs-js": () => import("./../../../src/templates/packs.js" /* webpackChunkName: "component---src-templates-packs-js" */)
}

